import {
  // Trick prettier to format global styles as css
  createGlobalStyle as css
} from 'styled-components';
import { colorsCss, alphasCss } from '@safetywing-tech/company-ui';
import { th } from 'style/theme';
import { sizes } from './index';
import '@safetywing-tech/feather/styles.css';
import './fonts.css';

const GlobalStyle = css`
  :root {
    ${colorsCss};
    ${alphasCss};
  }

  html,
  body {
    width: 100%;
    height: 100%;
  }

  input,
  textarea,
  select {
    outline: none;
    border: 0;
  }

  body {
    margin: 0;
    padding: 0;
    font: 100%/1.33 ${th('fonts.body')};
    color: ${th('colors.primaryText')};
    background-color: ${th('colors.black')};
    -webkit-tap-highlight-color: ${th('colors.tapHighlight')};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
  }

  h1 {
    font-size: 30px;
    margin: 0;
    line-height: 1;
    letter-spacing: -1.34px;

    @media ${sizes.tabletPortrait} {
      font-size: 40px;
    }
  }

  /** Fix for toolip bug, https://react-tooltip.com/docs/troubleshooting#the-tooltip-doesnt-close */
  :root {
    --rt-opacity: 1;
  }
`;

export default GlobalStyle;
